import Vue from 'vue';
import { Media } from '@/node_modules/@osp/design-system/types/media';
import { useCmsContentStore, clientInitCmsContentStore } from '~/@api/store/cmsContentApi';
import { clientInitRoutingStore, useRoutingStore } from '~/@api/store/routingApi';
import { clientInitUserStore, useUserStore } from '~/@api/store/userApi';
import { SpaLink, SpaLinkRel } from '~/generated/hybris-raml-api';
import { getDynamicYieldScripts, getTrackingPreconnects } from '~/tracking/trackingutils';
import DefaultHtmlHeadPreconnects from '~/nuxt.config.head.preconnect.js';
import DefaultHtmlHeadLinks from '~/nuxt.config.head.link.js';
import { importCmsContentApi, importUserApi } from '~/app-utils/dynamic-imports';
import { clientInitServerContextStore } from '~/@api/store/serverContextApi';
import BaseTemplate from '~/layouts/base-template/base-template';

const sanitizeHeadLinks = (links = []) => {
	return links.filter((link, idx) => {
		if (link.rel === 'canonical') {
			return links.indexOf(links.find((l) => l.rel === 'canonical')) === idx;
		}

		return true;
	});
};

export default Vue.extend({
	extends: BaseTemplate,
	props: {
		footer: { type: Boolean, default: true },
		header: { type: Boolean, default: true },
	},
	data() {
		return {
			showDYRecommendationsSlot: false,
			showMiddleContentSlot: false,
			renderMiddleContent: false,
			renderMessageboxSlot: false,
			routingHeadData: { links: [], meta: null, title: '' },
			htmlLang: 'de',
			trackingPreconnects: [],
			dynamicYieldScripts: [],
		};
	},
	head() {
		return {
			htmlAttrs: {
				lang: this.htmlLang,
			},
			link: [
				...DefaultHtmlHeadPreconnects,
				...this.trackingPreconnects,
				...sanitizeHeadLinks(this.routingHeadData.links),
				...DefaultHtmlHeadLinks,
			],
			meta: this.routingHeadData.meta as any,
			title: this.routingHeadData.title,
		};
	},
	watch: {
		'$store.state.user.language.isocode': {
			immediate: true,
			handler() {
				importUserApi().then(async () => {
					if (process.client) {
						await clientInitUserStore(this.$store);
					}

					this.htmlLang = useUserStore(this.$store).state.user?.language?.isocode || 'de';
				});
			},
		},
		'$store.state.slots': {
			immediate: true,
			deep: true,
			handler() {
				if (process.client) {
					importCmsContentApi().then(async () => {
						await clientInitCmsContentStore(this.$store);

						this.updateSlots();
					});

					return;
				}

				this.updateSlots();
			},
		},
		'$route.fullPath': {
			immediate: true,
			handler() {
				if (process.client) {
					clientInitRoutingStore(this.$store).then(this.updateRoutingHead);
					return;
				}

				this.updateRoutingHead();
			},
		},
	},
	beforeCreate() {
		this.$root.$on('preload', (event) => {
			const preloads = this['getPreloads'](event); // eslint-disable-line dot-notation

			if (preloads.length > 0) {
				preloads.forEach((source) => {
					if (source.as === 'image') {
						source.fetchpriority = 'high';
					}
				});

				useRoutingStore(this.$store).api.addLinks(preloads);
				this['updateRoutingHead']?.(); // eslint-disable-line dot-notation
			}
		});
	},
	created() {
		this.updateTrackingPreconnects();
		this.loadDynamicYieldScripts();
	},
	methods: {
		updateSlots() {
			const slots = useCmsContentStore(this.$store)?.state?.slots;
			this.showDYRecommendationsSlot = !!slots?.DYRecommendations;
			this.showMiddleContentSlot = !!slots?.MiddleContent;
			this.renderMiddleContent = !!slots?.MiddleContent;
			this.renderMessageboxSlot = !!slots?.MessageboxSlot;
		},
		updateRoutingHead() {
			const { state: routingState } = useRoutingStore(this.$store);

			this.routingHeadData = {
				links: sanitizeHeadLinks(routingState.spaData.links),
				meta: routingState.spaData.meta as any,
				title: routingState.spaData.title,
			};
		},
		loadDynamicYieldScripts() {
			if (process.client) {
				clientInitRoutingStore(this.$store).then(async () => {
					await clientInitServerContextStore(this.$store);

					this.dynamicYieldScripts = getDynamicYieldScripts(this);
				});

				return;
			}

			this.dynamicYieldScripts = getDynamicYieldScripts(this);
		},
		updateTrackingPreconnects() {
			if (process.client) {
				clientInitRoutingStore(this.$store).then(async () => {
					await clientInitServerContextStore(this.$store);

					this.trackingPreconnects = getTrackingPreconnects(this.$store);
				});

				return;
			}

			this.trackingPreconnects = getTrackingPreconnects(this.$store);
		},
		getPreloads(event) {
			const preloads: SpaLink[] = [];

			if (event.type === 'picture') {
				const image = event.data as Media;
				let lastMinWidth = null;

				// Preload picture sources (the sources must be ordered! smallest source at last)
				image.sources?.forEach((source) => {
					preloads.push({
						rel: SpaLinkRel.preload,
						as: 'image',
						href: source.srcset,
						media: source.media + (lastMinWidth ? ` and (max-width: ${lastMinWidth - 1}px)` : ''),
					});

					const size = parseInt(source.media.replace(/\D/g, ''));

					if (size) {
						lastMinWidth = size;
					}
				});
				// Preload picture image
				preloads.push({
					rel: SpaLinkRel.preload,
					as: 'image',
					href: image.src,
					...(lastMinWidth && {
						media: `(max-width: ${lastMinWidth - 1}px)`,
					}),
				});
			} else if (event.type === 'image') {
				const image = event.data;

				// Preload image sources (deprecated - Scene7ImageLoader)
				preloads.push({
					rel: SpaLinkRel.preload,
					as: 'image',
					href: image.src,
					imagesrcset: image.srcset,
					imagesizes: image.sizes,
				});
			} else {
				// Preload anything else
				preloads.push({ rel: SpaLinkRel.preload, href: event.data });
			}

			return preloads;
		},
	},
});
